import {
  Anchor,
  Avatar,
  Button,
  Card,
  Code,
  Group,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import React from "react";
import { PersonalData } from "../api/user/profile";
import { CompanyData } from "../api/tenant/profile";
import { LocaleData } from "../api/user/locale";
import { Edit, Edit2 } from "iconsax-react";
import { useAction, useGlobalState } from "piral-core";
import { MemberPasswordChangeFeature } from "../../../features/members/password";
import { getDocumentReference } from "../../../shared/library/utils";

export const PreferencesSettingsWidget = () => {
  return (
    <Stack py={"md"} gap={"md"}>
      <AvatarContainer />
      <PersonalInformation />
      <CompanyInformation />
      <LocaleInformation />
    </Stack>
  );
};

const AvatarContainer = () => {
  const profile = useGlobalState((m) => m.profile);
  const setDrawer = useAction("setDrawer");

  const ProfileData = {
    avatar: getDocumentReference("AVATAR", profile.org.org_id, profile.id),
    fullname: `${profile.user.given_name} ${profile.user.family_name}`,
    group: `${profile.user.group}`,
    username: `${profile.user.username}`,
  };
  return (
    <Card withBorder radius={"md"} p="md">
      <Group gap="md" align="flex-start" py={"sm"}>
        <Avatar size={100} src={ProfileData?.avatar} />
        <Stack py={10} gap={5} h={"100%"}>
          <Title fw={500} c={"adiba"} order={4}>
            {ProfileData?.fullname}
          </Title>
          <Text fw={300} c={"adiba.4"} fz={14}>
            {ProfileData?.group}
          </Text>
          <Text fw={300} c={"adiba.4"} fz={14}>
            {ProfileData?.username}
            <Anchor
              ml={10}
              c={"altiba.4"}
              fw={300}
              onClick={() => {
                const page = <MemberPasswordChangeFeature />;
                const title = "Change Password";
                setDrawer(page, title);
              }}
              fz={11}
              component="a"
            >
              {" "}
              *change password*{" "}
            </Anchor>
          </Text>
        </Stack>
        <Space flex={1} />
        <Button
          color={"adiba.4"}
          fw={300}
          variant="outline"
          size="xs"
          leftSection={<Edit2 size={16} className="ics-path-override" />}
        >
          Edit
        </Button>{" "}
      </Group>
    </Card>
  );
};

const PersonalInformation = () => {
  return (
    <Card withBorder radius={"md"} p="md">
      <Group justify="space-between" p={0}>
        <Title order={6} py="xs" c="adiba.5">
          Personal Information
        </Title>
        <Button
          color={"adiba.4"}
          fw={300}
          variant="outline"
          size="xs"
          leftSection={<Edit2 size={16} className="ics-path-override" />}
        >
          Edit
        </Button>
      </Group>
      {PersonalData().map((item, index) => {
        return (
          <Group py={10} key={index}>
            <Text fw={300} fz="sm" c="dimmed" w={200} truncate>
              {item.label}
            </Text>
            <Text c="adiba" fw={300} fz="sm" truncate>
              {item.value}
            </Text>
          </Group>
        );
      })}
    </Card>
  );
};

const CompanyInformation = () => {
  return (
    <Card withBorder radius={"md"} p="md">
      <Group justify="space-between" p={0}>
        <Title order={6} py="xs" c="adiba.5">
          Company Information
        </Title>
        <Button
          color={"adiba.4"}
          fw={300}
          variant="outline"
          size="xs"
          leftSection={<Edit2 size={16} className="ics-path-override" />}
        >
          Edit
        </Button>
      </Group>
      {CompanyData().map((item, index) => {
        return (
          <Group py={10} key={index}>
            <Text fw={300} fz="sm" c="dimmed" w={200} truncate>
              {item.label}
            </Text>
            <Text c="adiba" fw={300} fz="sm" truncate miw={100}>
              {item.value}
            </Text>
          </Group>
        );
      })}
    </Card>
  );
};

const LocaleInformation = () => {
  return (
    <Card withBorder radius={"md"} p="md">
      <Group justify="space-between" p={0}>
        <Title order={6} py="xs" c="adiba.5">
          Localization Settings
        </Title>
        <Button
          color={"adiba.4"}
          fw={300}
          variant="outline"
          size="xs"
          leftSection={<Edit2 size={16} className="ics-path-override" />}
        >
          Edit
        </Button>
      </Group>
      {LocaleData().map((item, index) => {
        return (
          <Group py={10} key={index}>
            <Text fw={300} fz="sm" c="dimmed" w={200} truncate>
              {item.label}
            </Text>
            <Text c="adiba" fw={300} fz="sm" miw={100} truncate>
              {item.value}
            </Text>
          </Group>
        );
      })}
    </Card>
  );
};
