import { GlobalStateContext } from "piral-core";

declare module "piral-core/lib/types/custom" {
  interface PiralCustomActions {
    setActiveApp: (MenuApplication: string) => void;
    setContextApps: (ContextApplications: any[]) => void;
  }

  interface PiralCustomState {
    activeapp: string;
    applications: any[];
    profile: {
      id: string;
      user: {
        given_name?: string;
        family_name?: string;
        username: string;
        group?: string;
        job_title: string;
      };
      contact: {
        email: string;
        email_verified?: boolean;
        phone_number?: string;
        phone_verified?: boolean;
      };
      settings: {
        zoneinfo: string;
        language: string;
        default_connection: string;
      };
      org: {
        org_id: string;
        org_name: string;
        org_email: string;
      };
      meta: {
        currency: string;
        numberFormat: string;
        language: string;
      };
    };
  }
}

export const setActiveApp = (
  ctx: GlobalStateContext,
  ActiveApplication: string
) => {
  ctx.dispatch((state) => ({
    ...state,
    activeapp: ActiveApplication,
  }));
};

export const setContextApps = (
  ctx: GlobalStateContext,
  ContextApplications: any[]
) => {
  ctx.dispatch((state) => ({
    ...state,
    applications: ContextApplications,
  }));
};
