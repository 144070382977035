import React from "react";
import { CenteredLayout } from "../../layouts";
import BarLoader from "react-spinners/BarLoader";
import { Anchor, Image, Stack, Text } from "@mantine/core";

export const AppLoadingIndicator = () => {
  return (
    <CenteredLayout>
      <Stack align="center">
        <Image
          src={"https://console.adiba.app/public/adiba-console.svg"}
          w={300}
        />
        <BarLoader
          color="#c98c4c"
          cssOverride={{
            borderRadius: 4,
          }}
          height={6}
          loading
          speedMultiplier={0.6}
          width={300}
        />
        <Anchor td='none'>{' '}</Anchor>
        </Stack>
    </CenteredLayout>
  );
};
