import { Center, MantineProvider, Box } from "@mantine/core";
import React from "react";

export function CenteredLayout({ children }: { children: React.ReactNode }) {
  return (
    <MantineProvider>
            <Center bg={'gray.1'} h={'100vh'}>{children}</Center>
    </MantineProvider>
  );
}
