import { useGlobalState } from "piral-core";

    
//TODO: Coming from either CONSOLE or IAM
export const LocaleData = () => {
    const profile = useGlobalState((m) => m.profile);
    return [{
    label: "Language:",
    value: profile.meta.language
},
{
    label: "Timezone:",
    value: profile.settings.zoneinfo
},
{
    label: "Currency:",
    value: profile.meta.currency
},
{
    label: "Numbering:",
    value: profile.meta.numberFormat
}]
};