import * as React from "react";
import { ComponentsState, useAction, useGlobalState } from "piral";
import {
  Divider,
  MantineProvider,
  Menu as MantineMenu,
  Text,
} from "@mantine/core";
import { theme } from "../../../theme.tsx";
import { PortalLayout } from "../../pages/layouts";
import { SetApplicationSideMenu } from "../../widgets/navigation/libs/menus/menuitems.tsx";
import { NAVIGATION_CONSTANT } from "../../../constants.ts";
import {
  ProcessEngineDashboardHomePage,
  RetailEngineDashboardHomePage,
  PaymentEngineDashboardHomePage,
  LedgerSyncDashboardHomePage,
} from "../../pages/core/dashboard";
import { AppLoadingIndicator } from "../../pages/core/loading/index.tsx";

export const layout: Partial<ComponentsState> = {
  LoadingIndicator: AppLoadingIndicator,
  Layout: ({ children }) => {
    const applications = useGlobalState((m) => m.applications || []);
    const setMenu = useAction("setMenu");
    SetApplicationSideMenu(applications, setMenu);
    return  (  
      <>
        <MantineProvider theme={theme}>
          <PortalLayout>{children}</PortalLayout>
        </MantineProvider>
      </>
    );
  },
  DashboardContainer: ({ children }) => {
    const setPageMeta = useAction("setPageMeta");
    const [application, profile] = useGlobalState((m) => [m.activeapp, m.profile] || ["", null]);
    const logged_user = `${profile?.user.given_name} ${profile?.user.family_name}`;
    setPageMeta(
      `Welcome, ${logged_user}`,
      `${profile?.org?.org_name}`
    );
    const Dashboard = {
      "retail-engine": (
        <RetailEngineDashboardHomePage>
          {children}
        </RetailEngineDashboardHomePage>
      ),
      "process-engine": (
        <ProcessEngineDashboardHomePage>
          {children}
        </ProcessEngineDashboardHomePage>
      ),
      "payment-engine": (
        <PaymentEngineDashboardHomePage>
          {children}
        </PaymentEngineDashboardHomePage>
      ),
      "ledger-sync": (
        <LedgerSyncDashboardHomePage>{children}</LedgerSyncDashboardHomePage>
      ),
    };
    return Dashboard[application];
  },
  MenuContainer: ({ children, type }) => {
    let Bookmarks;
    if (type === "user") {
      Bookmarks = !children.toString().length ? (
        <></>
      ) : (
        <>
          <Divider />
          <MantineMenu.Label pt={"xs"}>
            <Text size="xs">
              {NAVIGATION_CONSTANT.MENU.USER.SECTIONS.bookmarks}
            </Text>
          </MantineMenu.Label>{" "}
        </>
      );
    }
    return (
      <>
        {Bookmarks}
        {children}
      </>
    );
  },
};
