import React, { useEffect, useState } from "react";
import { ApplicationsDefaultFeature } from "../../../features/applications/default";
import { ApplicationsPreviewFeature } from "../../../features/applications/preview";
import { Popover } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useGlobalState } from "piral-core";
import { AppLibrary } from "../../../entities/applications/model/applications";

export const AppSwitcher = () => {
  const applicationId = useGlobalState(m => m.activeapp)
  const ApplicationState = useGlobalState(m => m.applications || []);
  const ApplicationData = ApplicationState.map((a) => a.code)

  const [opened, { toggle }] = useDisclosure(true);
  const ApplicationSet = new Set(ApplicationData);
  const ApplicationPreviewData = AppLibrary.filter(app => ApplicationSet.has(app.id))
  const ApplicationActiveItem = AppLibrary.filter(app => app.id === applicationId).shift()
  const application = ApplicationActiveItem?.title

  useEffect(() => {
    toggle()
  },[applicationId]);
  
  return (
    <Popover
      transitionProps={{ transition: "pop" }}
      position="bottom-start"
      withArrow
      withinPortal
      offset={0}
      arrowOffset={20}
      arrowSize={10}
      shadow="sm"
      radius="sm"
      opened={opened}
      onChange={toggle}
    >
      <ApplicationsDefaultFeature {...{ opened, toggle, application }} />
      <ApplicationsPreviewFeature {...{ApplicationPreviewData, toggle}}/>
    </Popover>
  );
};
