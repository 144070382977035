import { useGlobalState } from "piral-core";

//INFO: Coming from ADIBA
export const PersonalData = () => {
  const profile = useGlobalState((m) => m.profile);

  return [
    {
      label: "First Name:",
      value: profile.user.given_name,
    },
    {
      label: "Last Name:",
      value: profile.user.family_name,
    },
    {
      label: "Email Address:",
      value: profile.user.username,
    },
    {
      label: "Phone Number:",
      value: profile.contact.phone_number,
    },
  ];
};
