import { useGlobalState } from "piral-core";

export const CompanyData = () => {
    const profile = useGlobalState((m) => m.profile);

  return [
    {
      label: "Company Name:",
      value: profile.org.org_name,
    },
    {
      label: "Tenant Identifier:",
      value: profile.org.org_id,
    },
    {
      label: "Job Designation:",
      value: profile.user.job_title,
    },
    {
      label: "Company Email:",
      value: profile.org.org_email,
    },
  ];
};
