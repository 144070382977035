export const isUUID = (uuid) => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(uuid);
};

export const getDocumentReference = (
  template: string,
  tenant: string,
  identifier: string
) => {
  const templates = {
    AVATAR: `gcs:adb-backoffice-documents:_super:avatars:${tenant}`,
  };
  return `${templates[template]}:${identifier}`;
};

export const decipherLocale = (locale) => {
  const currency = "Nigerian Naira (NGN)",
    language = "English (EN)",
    numberFormat = "###,###.00";
  return { currency, numberFormat, language };
};

export const setupPage = (
  profile: any,
  subscriptions: any,
  org_email: string
) => {
  const {
    username,
    email,
    org_id,
    org_name,
    given_name,
    family_name,
    sub,
    phone_number,
    job_title,
    email_verified,
    phone_verified,
    zoneinfo,
    language,
    locale,
    default_app,
    default_connection,
  } = profile;

  const { applications, overdueInvoice, plan } = subscriptions?.data;

  const group = Array.isArray(profile["groups"])
    ? profile["groups"].join(", ")
    : profile["groups"];

  const renderProfile = {
    applications: applications,
    activeapp: default_app,
    profile: {
      id: sub,
      user: { given_name, family_name, username, group, job_title },
      contact: { email, email_verified, phone_number, phone_verified },
      settings: {
        zoneinfo,
        language,
        default_connection,
        invoice: overdueInvoice,
        plan: plan.name,
      },
      meta: decipherLocale(locale),
      org: { org_id, org_name, org_email },
    },
  };

  //TODO: Throw errors if anything is not in place here
  return renderProfile;
};

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
