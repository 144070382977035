import {
  setActiveApp,
  setContextApps,
} from "./entities/applications/actions/applications";
import { createOidcApi } from "piral-oidc";
import {
  setMenu,
  setActiveMenu,
  registerMenuItemApi,
} from "./widgets/navigation/actions/sidemenu";
import { createStandardApi } from "piral";
import { createInstance, Piral } from "piral-core";
import React from "react";
import { createRoot } from "react-dom/client";
import { errors } from "./pages/errors";
import { layout } from "./app/ui/_BaseLayout";

import { setDrawer, setPageMeta } from "./pages/layouts";
import { SettingsHomePage } from "./pages/core/settings/ui/home";
import { registerPiralActionApi } from "./app/actions/api";
import { client } from "../auth.config";
import { BasicGet } from "./shared/library/fetchers/basic";
import { ENDPOINT_CONSTANT } from "../constants";
import { setupPage } from "./shared/library/utils";
import { AppLoadingIndicator } from "./pages/core/loading";

const FEED_URL = process.env.FEED_URL || "http://localhost:3000/feed.json";
const root = createRoot(document.querySelector("#app"));

root.render(<AppLoadingIndicator />);
const instance = createInstance({
  actions: {
    setMenu: setMenu,
    setActiveMenu: setActiveMenu,
    setActiveApp: setActiveApp,
    setContextApps: setContextApps,
    setDrawer: setDrawer,
    setPageMeta: setPageMeta,
  },
  state: {
    components: layout,
    errorComponents: errors,
    drawer: {},
  },
  plugins: [
    ...createStandardApi(),
    registerMenuItemApi(),
    registerPiralActionApi(),
    createOidcApi(client),
  ],
  requestPilets() {
    return fetch(FEED_URL)
      .then((res) => {
        if (res.ok) {
          return res.json().then((res) => res.items);
        } else {
          console.error(
            `Error parsing pilets: ${res.status} ${res.statusText}`
          ); // Log the error
          return [];
        }
      })
      .catch((error) => {
        console.error(`Error fetching pilets: ${error}`);
        return [];
      });
  },
});

const auth = client.handleAuthentication();
auth
  .then(async ({ shouldRender, state }) => {
    if (state?.finalRedirectUri) {
      location.href = state.finalRedirectUri;
    } else if (shouldRender) {
      const token = await client.token();
      const profile = await client.account();
      const org_email = `info@${
        profile?.contact?.email.split("@")[1] || "adiba.app"
      }`;
      const active_subscriptions = await BasicGet(
        ENDPOINT_CONSTANT.SUBSCRIPTION_EP,
        token,
        profile["org_id"],
        profile["default_connection"]
      );

      const initialProfileData = setupPage(
        profile,
        active_subscriptions,
        org_email
      );
      console.log(initialProfileData);
      instance.context.dispatch((state) => ({
        ...state,
        ...initialProfileData,
      }));

      root.render(<Piral instance={instance} />);
    }
  })
  .catch((err) => {
    console.error("Problems with authentications model", err);
    //TODO: Replace with AppLoadingErrorIndicator
    root.render(<AppLoadingIndicator />);
  });

instance.root.registerPage("/settings/:category", SettingsHomePage);
instance.context.emit("abc-menu-loaded", {});