import {
  Button,
  Group,
  Avatar,
  Stack,
  Menu as MantineMenu,
  Text,
  Divider,
  Badge,
} from "@mantine/core";
import { NAVIGATION_CONSTANT } from "../../../../../constants";
import {
  ArrowDown2,
  ArrowRight2,
  Barcode,
  Key,
  LogoutCurve,
  ProfileCircle,
  SliderHorizontal,
} from "iconsax-react";
import { Menu, useAction, useGlobalState } from "piral";
import React from "react";
import { MemberProfileViewFeature } from "../../../../features/members/profile";
import { MemberPasswordChangeFeature } from "../../../../features/members/password";
import { Link } from "react-router-dom";
import { client } from "../../../../../auth.config";

export const UserMenuTrigger = () => {
  const profile = useGlobalState((m) => m.profile || null);
  const LoggedInMember = `${profile?.user?.given_name} ${profile?.user?.family_name}`;
  const MemberUsername = profile?.user?.username;
  const CurrentPlan = "Basic";
  const DueInvoices = "4";

  const UserMenuSize = 40;

  const setDrawer = useAction('setDrawer');
  return (
    <>
      <MantineMenu
        width={250}
        transitionProps={{ transition: "pop" }}
        position="bottom-end"
        withArrow
        withinPortal
        offset={5}
        arrowOffset={20}
        arrowSize={10}
      >
        <MantineMenu.Target>
          <Button variant="subtle" h={50} px={10} radius="md">
            <Group gap="sm">
              <Avatar radius="xl" />
              <Stack gap={0} align="flex-start" visibleFrom="md">
                <Text size="sm">{LoggedInMember}</Text>
                <Text c="dimmed" size="xs">
                  {MemberUsername}
                </Text>
              </Stack>
              <ArrowDown2 size={16} />
            </Group>
          </Button>
        </MantineMenu.Target>
        <MantineMenu.Dropdown>
          {/* VIEW PROFILE */}
          <MantineMenu.Item
            h={UserMenuSize}
            leftSection={
              <ProfileCircle size={16} className="ics-path-override" />
            }
            rightSection={
              <ArrowRight2 size={12} className="ics-path-override" />
            }
            onClick={() => {
              const page = <MemberProfileViewFeature/>
              const title = 'Member Profile'
              setDrawer(page, title)
            }}
          >
            <Text size="xs" fw={300}>
              {NAVIGATION_CONSTANT.MENU.USER.ITEMS.profile}
            </Text>
          </MantineMenu.Item>
          {/* CHANGE PASSWORD */}
          <MantineMenu.Item
            h={UserMenuSize}
            leftSection={<Key size={16} className="ics-path-override" />}
            rightSection={
              <ArrowRight2 size={12} className="ics-path-override" />
            }
            onClick={() => {
              const page = <MemberPasswordChangeFeature/>
              const title = 'Change Password'
              setDrawer(page, title)
            }}
          >
            <Text size="xs" fw={300}>
              {NAVIGATION_CONSTANT.MENU.USER.ITEMS.password}
            </Text>
          </MantineMenu.Item>
          <Divider />
          <MantineMenu.Label pt={"xs"}>
            <Text size="xs">
              {NAVIGATION_CONSTANT.MENU.USER.SECTIONS.subscriptions}
            </Text>
          </MantineMenu.Label>
          {/* CURRENT PLAN */}
          <MantineMenu.Item
            h={UserMenuSize}
            leftSection={
              <SliderHorizontal size={16} className="ics-path-override" />
            }
            rightSection={
              <Badge size="sm" color="adiba.2">
                {CurrentPlan}
              </Badge>
            }
            component={Link}
            to={'/settings/subscriptions'}
            onClick={void 0}
          >
            <Text size="xs" fw={300}>
              {NAVIGATION_CONSTANT.MENU.USER.ITEMS.plans}
            </Text>
          </MantineMenu.Item>
          {/* DUE INVOICES */}
          <MantineMenu.Item
            h={UserMenuSize}
            leftSection={<Barcode size={16} className="ics-path-override" />}
            rightSection={
              <Badge size="sm" color="red">
                {DueInvoices}
              </Badge>
            }
            component={Link}
            to={'/settings/subscriptions'}
            onClick={void 0}
          >
            <Text size="xs" fw={300}>
              {NAVIGATION_CONSTANT.MENU.USER.ITEMS.invoices}
            </Text>
          </MantineMenu.Item>
          <Menu type="user" />
          <Divider />
          {/* LOGOUT */}
          <MantineMenu.Item
            h={UserMenuSize}
            leftSection={
              <LogoutCurve
                size={16}
                color="red"
                className="ics-path-override"
              />
            }
            onClick={() => client.logout()}
          >
            <Text size="xs" c="red" fw={300}>
              {NAVIGATION_CONSTANT.MENU.USER.ITEMS.logout}
            </Text>
          </MantineMenu.Item>
        </MantineMenu.Dropdown>
      </MantineMenu>
    </>
  );
};
